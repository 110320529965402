.container {
    max-width: var(--MAXWIDTH);
    width: 40%;
    margin: 10px auto 10px;
    padding-bottom: 10px;
    position: relative;
    color: var(--text-color);
}

.headerSection {
    display: inline-block;
position: relative;
    padding: 0 clamp(5px, 1.9vw, 20px);
}

.headerSection::before, .headerSection::after  {
    content: "";
    background-image: url("../../assets/images/rocket.png");
    position: absolute;
    width: 30px;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.agent{

    position: relative;
    /*padding: 0 clamp(5px, 1.9vw, 20px);*/
    margin-right: 15px;
}

.agent::after  {
    content: "";
    background-image: url("../../assets/images/detective.png");
    position: absolute;
    width: 30px;
    height: 20px;

    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}



.headerSection::before {
    left: 100%;
}
.headerSection::after {
    top: 0;
    right: 100%;
}
.mainHeader {
    padding: 0;
    margin: 0;
    font-size: clamp(12px, 1.2vw, 20px);
}

.subHeader {
    padding: 0;
    margin: 2px;
    font-weight: 400;
    font-size: clamp(12px, 1.2vw, 20px);
}

.description {
    text-align: left;
    color: var(--text-color);
    font-size: clamp(12px, 1.2vw, 16px);
    line-height: 22px;
}


@media screen and (max-width: 768px) {
.container {
    width: 90%;
}
}

@media screen and (max-width: 576px){
.headerSection {
    width: 80%;
}
}
